import * as yup from "yup";

export const complaintRadioSchema = {
  complaintRadio: yup.string().required("Please select an option to continue."),
};

export const vehicleRegSchema = {
  vehicleRegNo: yup.string().required("Please select an option to continue."),
};

export const complaintDescriptionSchema = {
  complaintDescription: yup
    .string()
    .required("Please tell us what's gone wrong.")
    .min(10, "Please enter at least 10 characters.")
    .matches(/[A-Z]/gi, "Please enter correct details."),
};

export const impactDescriptionSchema = {
  impactDescription: yup
    .string()
    .required("Please tell us what the impact has been.")
    .min(10, "Please enter at least 10 characters.")
    .matches(/[A-Z]/gi, "Please enter correct details."),
};

export const customerExpectationSchema = {
  customerExpectation: yup
    .string()
    .required("Please tell us how you would like us to resolve it.")
    .min(10, "Please enter at least 10 characters.")
    .matches(/[A-Z]/gi, "Please enter correct details."),
};
