import { Row } from "react-bootstrap";
import Tile from "../../component/tile/Tile";

const HelpPageTiles = () => {
  return (
    <Row>
      <Tile
        cardId="startYourComplaint"
        cardTitle="How to complain"
        cardLinkUrl="/complaint"
        cardLinkContent="Start your complaint"
        isLink={true}
      >
        If you're unhappy with our service, let us know and we'll try put things
        right.
      </Tile>

      <Tile
        cardId="paymentsSupport"
        cardTitle="Payments support"
        cardLinkUrl="/payments/make-a-payment"
        cardLinkContent="Payments"
        isLink={true}
      >
        See how to make a payment or seek help if you are struggling with your
        finances.
      </Tile>
      <Tile
        cardId="accessibilitySupport"
        cardTitle="Accessibility and support"
        cardLinkUrl="/help/accessibility-home"
        cardLinkContent="Find out more"
        isLink={true}
      >
        Please tell us more about yourself. This helps us to make sure we're
        supporting you in the best way.
      </Tile>
    </Row>
  );
};

export default HelpPageTiles;
