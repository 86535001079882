import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form, Row } from "react-bootstrap";
import steps from "../complaint-steps";
import magicStrings from "../../../utils/magic-string";
import useAuth from "../../../hooks/use-auth/useAuth";
import BasePage from "../../basepages/basepage/BasePage";
import { setItem } from "../../../storage/SessionStorage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import ComplaintNavigation from "../../../component/complaint-navigation/ComplaintNavigation";
import {
  step,
  contactDetails,
} from "../../../redux/features/complaints/complaintSlice";

const ContactDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileValidation, setMobileValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);

  useComplaintStepCheck(steps.contactDetails, "/complaint/details");

  const { auth } = useAuth();
  const userProfile = auth?.user;

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = () => {
    const isMobileNumberEmpty =
      userProfile?.mobileNumber === "" || userProfile?.mobileNumber === null;
    const isEmailAddressEmpty =
      userProfile?.emailAddress === "" || userProfile?.emailAddress === null;

    if (isMobileNumberEmpty) {
      setMobileValidation(true);
    }
    if (isEmailAddressEmpty) {
      setEmailValidation(true);
    }
    if (isMobileNumberEmpty || isEmailAddressEmpty) {
      return false;
    }

    dispatch(
      contactDetails({
        fullName:
          userProfile?.title +
          " " +
          userProfile?.firstName +
          " " +
          userProfile?.lastName,
        mobileNumber: userProfile?.mobileNumber,
        workNumber: userProfile?.workNumber,
        homeNumber: userProfile?.homeNumber,
        emailAddress: userProfile?.emailAddress,
        userAddress: {
          addressLine1: userProfile?.userAddress?.addressLine1,
          addressLine2: userProfile?.userAddress?.addressLine2,
          addressLine3: userProfile?.userAddress?.addressLine3,
          city: userProfile?.userAddress?.city,
          county: userProfile?.userAddress?.county,
          postCode: userProfile?.userAddress?.postCode,
        },
      })
    );
    dispatch(step(steps.complaintSummary));
    navigate("/complaint/summary", { replace: true });
  };

  const navigateToProfile = () => {
    setItem("isComplaintJourney", true);
    navigate("/profile", { replace: true });
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 fw-bold">Your contact details</h2>
        <div className="fw-bold mt-2">Please check everything's correct.</div>
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            name="contactDetailsForm"
          >
            <div>
              If anything's changed, you can{" "}
              <span
                onClick={navigateToProfile}
                className="text-decoration-underline fw-bold text-primary cursor-pointer"
              >
                edit your details
              </span>{" "}
              in your profile.
            </div>
            <dl className="mt-3 border container bg-light rounded">
              <Row className="border-bottom m-0 mt-2">
                <dt className="col-sm-6 col-md-6 p-0">Your name</dt>
                <dd
                  className={`col-sm-6 col-md-6 p-0 text-break ${magicStrings.maskInformation}`}
                >
                  {userProfile?.title +
                    " " +
                    userProfile?.firstName +
                    " " +
                    userProfile?.lastName}
                </dd>
              </Row>
              <Row className="border-bottom m-0 mt-2">
                <dt className="col-sm-6 col-md-6 p-0">Address</dt>
                <dd
                  className={`col-sm-6 col-md-6 p-0 text-break ${magicStrings.maskInformation}`}
                >
                  <div>{userProfile?.userAddress?.addressLine1}</div>
                  <div>{userProfile?.userAddress?.addressLine2}</div>
                  <div>{userProfile?.userAddress?.addressLine3}</div>
                  <div>{userProfile?.userAddress?.city}</div>
                  <div>{userProfile?.userAddress?.county}</div>
                  <div>{userProfile?.userAddress?.postCode}</div>
                </dd>
              </Row>
              <Row className="border-bottom m-0 mt-2">
                <dt className="col-sm-6 col-md-6 p-0">Mobile number</dt>
                <dd className="col-sm-6 col-md-6 p-0 text-break">
                  {userProfile?.mobileNumber === "" ||
                  userProfile?.mobileNumber === null ? (
                    <>
                      <Button
                        variant="outline-primary"
                        className={`px-2 py-1 w-100 text-primary bg-transparent ${
                          mobileValidation === true
                            ? "border-danger text-danger"
                            : ""
                        }`}
                        onClick={navigateToProfile}
                      >
                        Add mobile number
                      </Button>
                      <span
                        className={`invalid-feedback ${
                          mobileValidation === true ? "d-block" : ""
                        }`}
                      >
                        Please add mobile number
                      </span>
                    </>
                  ) : (
                    <span className={magicStrings.maskInformation}>
                      {userProfile?.mobileNumber}
                    </span>
                  )}
                </dd>
              </Row>
              <Row className="m-0 mt-2">
                <dt className="col-sm-6 col-md-6 p-0">Email address</dt>
                <dd className="col-sm-6 col-md-6 p-0 text-break">
                  {userProfile?.emailAddress === "" ||
                  userProfile?.emailAddress === null ? (
                    <>
                      <Button
                        variant="outline-primary"
                        className={`px-2 py-1 w-100 text-primary bg-transparent ${
                          emailValidation === true
                            ? "border-danger text-danger"
                            : ""
                        }`}
                        onClick={navigateToProfile}
                      >
                        Add email address
                      </Button>
                      <span
                        className={`invalid-feedback ${
                          emailValidation === true ? "d-block" : ""
                        }`}
                      >
                        Please add an email address
                      </span>
                    </>
                  ) : (
                    <span className={magicStrings.maskInformation}>
                      {userProfile?.emailAddress}
                    </span>
                  )}
                </dd>
              </Row>
            </dl>
            <div className="mt-4">
              <ComplaintNavigation
                previousName="Back"
                previousUrl="/complaint/details"
              />
            </div>
          </Form>
        </FormProvider>
      </div>
    </BasePage>
  );
};

export default ContactDetailsPage;
