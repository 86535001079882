import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import steps from "../complaint-steps";
import magicStrings from "../../../utils/magic-string";
import { getItem, removeItem } from "../../../storage/SessionStorage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import { reset } from "../../../redux/features/complaints/complaintSlice";

const ComplaintSuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const complaintNo = getItem("complaintNo");

  useComplaintStepCheck(steps.complaintSuccess, "/home");

  const onNavigate = () => {
    dispatch(reset());
    removeItem("complaintNo");
    navigate("/home", { replace: true });
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 mb-3 fw-bold">
          Thanks, we'll find out what's gone wrong
        </h2>
        <div className="mb-3">
          Your complaint reference number is{" "}
          <span className={`fw-bold ${magicStrings.maskInformation}`}>
            {complaintNo}
          </span>
          . We'll ask for it if you call us about your complaint. Please include
          it if you write to us.
        </div>
        <div className="bg-light py-3 ps-3 pe-2 border border-radius">
          <div className="fw-bold">Supporting your complaint</div>
          <div>
            Please email any evidence, such as pictures and service history, to{" "}
            <Link
              to={`mailto:LACustomerResolutionMailbox@lexautolease.co.uk?subject=Reference Number: ${complaintNo}`}
              className="text-break"
            >
              LACustomerResolutionMailbox@lexautolease.co.uk
            </Link>{" "}
            quoting your reference number:{" "}
            <span className={`fw-bold ${magicStrings.maskInformation}`}>
              {complaintNo}
            </span>
            .
          </div>
        </div>
        <h2 className="h4 fw-bold mt-4">Our complaint response times</h2>
        <div className="border border-primary py-3 mb-3 border-radius d-flex justify-content-center align-items-center">
          <div className="w-25 px-2">
            <span className="text-primary h4 fw-bold">1 day</span>
          </div>
          <div className="w-75 ps-3 pe-1 border-start border-primary">
            We'll reply straightaway with your reference number while we start
            to investigate the problem.
          </div>
        </div>
        <div className="border border-primary py-3 mb-3 border-radius d-flex justify-content-center align-items-center">
          <div className="w-25 px-2">
            <span className="text-primary h4 fw-bold">4 weeks</span>
          </div>
          <div className="w-75 ps-3 pe-1 border-start border-primary">
            If we can't fix things within four weeks, we'll send a text message
            to let you know our next steps.
          </div>
        </div>
        <div className="border border-primary py-3 mb-4 border-radius d-flex justify-content-center align-items-center">
          <div className="w-25 px-2">
            <span className="text-primary h4 fw-bold">8 weeks</span>
          </div>
          <div className="w-75 ps-3 pe-1 border-start border-primary">
            If we haven't solved the problem after eight weeks, we'll send
            another text message to update you while we continue our
            investigation.
          </div>
        </div>
        <div className="w-50 mx-auto">
          <Button
            id="btnHome"
            className="text-decoration-none w-100"
            onClick={onNavigate}
          >
            Home
          </Button>
        </div>
      </div>
    </BasePage>
  );
};

export default ComplaintSuccessPage;
