const queryKeys = {
  users: ["users"],
  user: (id) => {
    return ["users", id];
  },
  userProfile: ["user-profile"],
  contracts: ["contracts"],
  contract: (contractId) => {
    return ["contracts", contractId];
  },
  contractAmendLimits: (contractId) => {
    return ["contracts", "contract-amend-limits", contractId];
  },
  contractAmendAgreement: (contractId, caNumber) => {
    return ["contract-amend-agreement", contractId, caNumber];
  },
  contractAmendDetails: (contractId, caNumber) => {
    return ["contract-amend-details", contractId, caNumber];
  },
  contractAmendQuotes: ["contracts", "contract-amend-existing-quotes"],
  invoices: ["invoices"],
  supportingDocument: (documentName) => {
    return ["document-name", documentName];
  },
  addresses: (postcode) => {
    return ["address", postcode];
  },
  address: ["address"],
  vehicleCollection: {
    availableSlots: (date) => ["available-slots", date],
  },
  transactions: ["transactions"],
  documents: ["documents"],
  accountBalance: ["account-balance"],
  accountNotifications: ["account-notifications"],
  directDebit: {
    bankDetail: (sortcode) => {
      return ["bank-detail", sortcode];
    },
    mandate: ["mandate"],
  },
  complaints: {
    category: ["category"],
    reason: (categoryId) => {
      return ["reason", categoryId];
    },
  },
  paymentHolidayFinancialSupport: ["paymentHolidayFinancialSupport"],
  orders: ["orders"],
  order: (orderId) => {
    return ["orderId", orderId];
  },
  orderDocument: (documentOrderId, documentType) => {
    return ["document-order-details", documentOrderId, documentType];
  },
  supportNeeds: ["supportNeeds"],
};

export default queryKeys;
