const trimStartOnChange = (event) => {
  const { value } = event.target;
  event.target.value = value.trimStart();
};

const pluraliseText = (number, apostrophe = false) => {
  if (apostrophe) return number === 1 ? "" : "'s";
  else return number === 1 ? "" : "s";
};

const characterCountOnChange = (event) => {
  const { value, maxLength, id } = event.target;
  let charLeft = maxLength - value.trimStart().length;
  let charLeftText =
    charLeft <= 1
      ? `${charLeft} character left`
      : `${charLeft} characters left`;
  document.getElementsByClassName(id)[0].innerText = charLeftText;
  trimStartOnChange(event);
};

export { trimStartOnChange, pluraliseText, characterCountOnChange };
