import * as yup from "yup";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import steps from "../complaint-steps";
import magicStrings from "../../../utils/magic-string";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import * as fieldSchemas from "../../../component/forms/schemas/fields";
import { useContracts } from "../../../api/hooks/contracts";
import ComplaintNavigation from "../../../component/complaint-navigation/ComplaintNavigation";
import {
  step,
  contractDetails,
} from "../../../redux/features/complaints/complaintSlice";

const VehicleSelectionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOptions = useSelector((state) => state.complaint);
  const selectedVehicleRegNo =
    selectedOptions?.contractDetails?.contractId ??
    selectedOptions?.contractDetails;
  const defaultValues = {
    vehicleRegNo: selectedVehicleRegNo,
  };

  useComplaintStepCheck(steps.makeComplaint, "/complaint/make-complaint");

  const { contracts, isError } = useContracts();

  const vehicles = contracts.map((c) => ({
    id: c.contractId,
    registrationNumber: c.vehicle.registrationNumber,
  }));

  useEffect(() => {
    if (isError) {
      dispatch(step(steps.complaintFailure));
      navigate("/complaint/failure", { replace: true });
    }
  }, [isError, navigate, dispatch]);

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.vehicleRegSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const goBack = () => {
    navigate("/complaint/make-complaint", { replace: true });
  };

  const onSubmit = (data) => {
    const contract = contracts.find(
      (c) => Number(c.contractId) === Number(data.vehicleRegNo)
    );
    dispatch(contractDetails(contract ? contract : data.vehicleRegNo));
    dispatch(step(steps.complaintCategories));
    navigate("/complaint/categories", { replace: true });
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column gap-3 col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 fw-bold">
          Are you complaining about a specific vehicle?
        </h2>
        <div>This helps us to send your complaint to the right team.</div>

        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            name="vehicleSelectionForm"
          >
            <Form.Group className="mb-4" controlId="vehicleRegNo">
              <Form.Label>Which vehicle is your complaint about?</Form.Label>
              <Form.Select
                className={`${magicStrings.maskInformation}`}
                aria-label="Which vehicle is your complaint about?"
                {...register("vehicleRegNo")}
                isInvalid={errors.vehicleRegNo}
              >
                <option value="">{`${vehicles.length} Vehicle${
                  vehicles.length > 1 ? "s" : ""
                } found`}</option>
                {vehicles.map((vehicle, index) => (
                  <option value={vehicle.id} key={index}>
                    {vehicle.registrationNumber}
                  </option>
                ))}
                <option value="It's not about a vehicle">
                  It's not about a vehicle
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.vehicleRegNo?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="pt-1">
              <ComplaintNavigation
                isLink={false}
                previousName="Back"
                callBack={goBack}
              />
            </div>
          </Form>
        </FormProvider>
      </div>
    </BasePage>
  );
};

export default VehicleSelectionPage;
