import { Route, Routes } from "react-router-dom";
import ErrorNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";
import HowToComplainPage from "../pages/complaints/how-to-complain/HowToComplainPage";
import MakeComplaintPage from "../pages/complaints/make-complaint/MakeComplaintPage";
import VehicleSelectionPage from "../pages/complaints/vehicle-selection/VehicleSelectionPage";
import ComplaintCategoriesPage from "../pages/complaints/complaint-categories/ComplaintCategoriesPage";
import ComplaintReasonsPage from "../pages/complaints/complaint-reasons/ComplaintReasonsPage";
import ComplaintDetailsPage from "../pages/complaints/complaint-details/ComplaintDetailsPage";
import ContactDetailsPage from "../pages/complaints/contact-details/ContactDetailsPage";
import ComplaintSummaryPage from "../pages/complaints/complaint-summary/ComplaintSummaryPage";
import ComplaintSuccessPage from "../pages/complaints/complaint-success/ComplaintSuccessPage";
import ExistingComplaintPage from "../pages/complaints/existing-complaint/ExistingComplaintPage";
import ComplaintFailurePage from "../pages/complaints/complaint-failure/ComplaintFailurePage";

const ComplaintsRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<ErrorNotFoundPage />} />
      <Route index element={<HowToComplainPage />} />
      <Route path="/make-complaint" element={<MakeComplaintPage />} />
      <Route path="/vehicle-selection" element={<VehicleSelectionPage />} />
      <Route path="/categories" element={<ComplaintCategoriesPage />} />
      <Route path="/reasons" element={<ComplaintReasonsPage />} />
      <Route path="/details" element={<ComplaintDetailsPage />} />
      <Route path="/contact-details" element={<ContactDetailsPage />} />
      <Route path="/summary" element={<ComplaintSummaryPage />} />
      <Route path="/success" element={<ComplaintSuccessPage />} />
      <Route path="/failure" element={<ComplaintFailurePage />} />
      <Route path="/existing-complaint" element={<ExistingComplaintPage />} />
    </Routes>
  );
};

export default ComplaintsRoutes;
