import { Routes, Route, Navigate } from "react-router";

import LegalPage from "../pages/legal/LegalPage";
import PrivacyPolicyPage from "../pages/privacy-policy/PrivacyPolicyPage";
import AccessibilityPage from "../pages/accessibility/AccessibilityPage";
import ContactusPage from "../pages/contactus/ContactusPage";
import CauraPage from "../pages/caura/CauraPromoPage";
import VehiclesPage from "../pages/vehicles/VehiclesPage";
import VehicleDescriptionPage from "../pages/vehicles/VehicleDescriptionPage";
import CookiesPage from "../pages/cookies/CookiesPage";
import TermsConditionsPage from "../pages/terms-and-conditions/TermsConditionsPage";
import HomePage from "../pages/home/HomePage";
import RegisterPage from "../pages/register/RegisterPage";
import IiqPage from "../pages/register/iiq/IiqPage";
import UserCredentialPage from "../pages/register/user-credential/UserCredentialPage";
import IdVerificationPage from "../pages/register/id-verification/IdVerificationPage";
import RegisterConfirmationPage from "../pages/register/confirmation/RegisterConfirmationPage";
import FAQsPage from "../pages/faqs/FAQsPage";
import IndexPage from "../pages/index/IndexPage";
import LoginPage from "../pages/login/LoginPage";
import MaintenanceTyrePage from "../pages/maintenance-tyre/MaintenanceTyrePage";
import InvoicesPage from "../pages/invoices/InvoicesPage";
import TransactionsPage from "../pages/transactions/TransactionsPage";
import DocumentsPage from "../pages/documents/DocumentsPage";
import PaymentRoutes from "./PaymentRoutes";
import OrdersPage from "../pages/orders/OrdersPage";
import OrderReviewPage from "../pages/orders/review/OrderReviewPage";
import OrderDetailsPage from "../pages/orders/details/OrderDetailsPage";
import OrderSignedPage from "../pages/orders/signed/OrderSignedPage";
import OrderSignedFailurePage from "../pages/orders/signed/OrderSignedFailurePage";
import ProfilePage from "../pages/profile/ProfilePage";

import RequireAuth from "../component/require-auth/RequireAuth";
import DoNotRequireAuth from "../component/do-not-require-auth/DoNotRequireAuth";
import EarlyTerminationRoutes from "./EarlyTerminationRoutes";
import VehicleCollectionRoutes from "./VehicleCollectionRoutes";
import ErrorPageNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";
import ContractAmendsRoutes from "./ContractAmendsRoutes";
import HelpRoutes from "./HelpRoutes";
import ForgotLoginDetailsRoutes from "./ForgotLoginDetailsRoutes";
import ErrorBoundaryWrapper from "../component/errors/error-boundary/ErrorBoundaryWrapper";
import ComplaintsRoutes from "./ComplaintsRoutes";

const ApplicationRoutes = () => {
  return (
    <ErrorBoundaryWrapper>
      <Routes>
        {/* Public routes */}
        <Route path="*" element={<ErrorPageNotFoundPage />} />
        <Route path="/legal" element={<LegalPage />}></Route>
        <Route path="/privacy" element={<PrivacyPolicyPage />}></Route>
        <Route path="/accessibility" element={<AccessibilityPage />}></Route>
        <Route path="/contact-us" element={<ContactusPage />}></Route>
        <Route path="/cookies" element={<CookiesPage />}></Route>
        <Route
          path="/terms-and-conditions"
          element={<TermsConditionsPage />}
        ></Route>
        <Route
          path="signonline/faqs"
          element={<Navigate to="/faqs" replace />}
        />
        <Route path="/faqs" element={<FAQsPage />}></Route>

        {/* Public only routes. 
      These routes can only be accessed when user is not logged-in.
      If user is already logged-in then they are redirected to the home page.
    */}
        <Route element={<DoNotRequireAuth />}>
          <Route
            path="signonline/index"
            element={<Navigate to="/" replace />}
          />
          <Route
            path="signonline/login"
            element={<Navigate to="/login" replace />}
          />
          <Route path="/" element={<IndexPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/iiq" element={<IiqPage />} />
          <Route path="/user-credential" element={<UserCredentialPage />} />
          <Route path="/verification" element={<IdVerificationPage />} />
          <Route path="/confirmation" element={<RegisterConfirmationPage />} />
          <Route
            path="/forgot-login-details/*"
            element={<ForgotLoginDetailsRoutes />}
          />
        </Route>

        {/* Protected routes */}
        <Route element={<RequireAuth />}>
          <Route path="/home" element={<HomePage />} />
          <Route path="/help/*" element={<HelpRoutes />} />
          <Route path="/invoices" element={<InvoicesPage />} />
          <Route path="/transactions" element={<TransactionsPage />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/payments/*" element={<PaymentRoutes />} />
          <Route path="/maintenance-tyres" element={<MaintenanceTyrePage />} />
          <Route path="/promo-caura" element={<CauraPage />}></Route>
          <Route
            path="/contract-amendment/*"
            element={<ContractAmendsRoutes />}
          />
          <Route
            path="/early-termination/*"
            element={<EarlyTerminationRoutes />}
          />
          <Route
            path="/vehicle-collection/*"
            element={<VehicleCollectionRoutes />}
          ></Route>
          <Route path="/complaint/*" element={<ComplaintsRoutes />}></Route>
          <Route path="/orders">
            <Route index element={<OrdersPage />} />
            <Route path="order-details" element={<OrderDetailsPage />} />
            <Route
              path="order-review/document/:documentNumber"
              element={<OrderReviewPage />}
            />
            <Route path="order-signed" element={<OrderSignedPage />} />
            <Route
              path="order-signed-failure"
              element={<OrderSignedFailurePage />}
            />
          </Route>
          <Route path="/vehicles">
            <Route index element={<VehiclesPage />} />
            <Route
              path="vehicle-description"
              element={<VehicleDescriptionPage />}
            />
          </Route>
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
      </Routes>
    </ErrorBoundaryWrapper>
  );
};

export default ApplicationRoutes;
