import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import { Link } from "react-router-dom";

const ComplaintNavigation = ({
  isLink = true,
  previousName = "Cancel",
  nextName = "Continue",
  previousUrl = "/home",
  callBack,
}) => {
  const onNavigation = () => {
    callBack();
  };
  return (
    <div className="d-flex justify-content-between align-items-center border-top pt-3">
      <div>
        <ArrowLeftIcon
          size="xs"
          className="pt-1 text-primary"
          title="Move to previous page"
        />
        {isLink ? (
          <Link to={previousUrl} className="text-decoration-underline">
            {previousName}
          </Link>
        ) : (
          <Button variant="link" className="p-0 fw-bold" onClick={onNavigation}>
            {previousName}
          </Button>
        )}
      </div>
      <Button type="submit" id="btnSubmit">
        {nextName}
      </Button>
    </div>
  );
};

ComplaintNavigation.propTypes = {
  isLink: PropTypes.bool,
  previousName: PropTypes.string,
  nextName: PropTypes.string,
  previousUrl: PropTypes.string,
  callBack: PropTypes.func,
};

export default ComplaintNavigation;
