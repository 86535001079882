import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";

const getAddressesByPostcode = async (axios, postcode) => {
  return axios.get(`/addresslookup/${postcode}`).then((res) => res.data);
};

const getAddressByLineId = async (axios, lineId) => {
  if (!lineId) {
    return Promise.resolve({});
  }

  return axios.get(`/addresslookup/addresses/${lineId}`).then((res) => {
    return res.data;
  });
};

const useAddressesByPostcode = (postcode) => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    refetch,
    isSuccess,
  } = useQuery(
    queryKeys.addresses(postcode),
    () => getAddressesByPostcode(axios, postcode),
    {
      enabled: false,
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000,
      select: (data) => {
        if (Array.isArray(data)) {
          return data.filter(
            (address) =>
              typeof address.postcode === "string" &&
              typeof postcode === "string" &&
              postcode.trim().toLowerCase().replace(" ", "") ===
                address.postcode.trim().toLowerCase().replace(" ", "")
          );
        }
        return [];
      },
    }
  );
  return { addresses: data, isError, refetch, isSuccess };
};

const useAddressByLineId = (lineId) => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const { data = fallback, isError } = useQuery(
    lineId ? queryKeys.addresses(lineId) : queryKeys.address,
    () => getAddressByLineId(axios, lineId),
    {
      enabled: true,
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000,
    }
  );
  const maxLength = 50;
  if (
    data &&
    typeof data?.addressLine1 === "string" &&
    data.addressLine1.length > maxLength
  ) {
    data.addressLine1 = data.addressLine1.substring(0, maxLength);
  }
  if (
    data &&
    typeof data?.addressLine2 === "string" &&
    data.addressLine2.length > maxLength
  ) {
    data.addressLine2 = data.addressLine2.substring(0, maxLength);
  }
  if (
    data &&
    typeof data?.addressLine3 === "string" &&
    data.addressLine3.length > maxLength
  ) {
    data.addressLine3 = data.addressLine3.substring(0, maxLength);
  }
  return { address: data, isError };
};

export { useAddressesByPostcode, useAddressByLineId };
