import { Button, CloseButton, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import NotificationBanner from "../NotificationBanner";
import WarningOutlineIcon from "../../icons/WarningOutlineIcon";
import InfoIcon from "../../icons/InfoIcon";
import useAuth from "../../../hooks/use-auth/useAuth";
import { formatCurrency } from "../../../utils/pages/number-format-utils";
import { formatDate } from "../../../utils/pages/date-format-utils";
import { setItem, getItem } from "../../../storage/SessionStorage";
import magicStrings from "../../../utils/magic-string";

const HomeBanner = ({ balanceDetails, onPayClicked, isHomePage = true }) => {
  const [showBanner, setShowBanner] = useState(false);
  const { auth } = useAuth();
  const userRegulated = auth?.user?.regulated;
  const dueAmount =
    balanceDetails?.balanceStatus === "PartiallyInArrears"
      ? balanceDetails?.amountDue - balanceDetails?.amountOverdue
      : balanceDetails?.amountDue;
  const accountDueNotSchedule =
    balanceDetails?.balanceStatus === "PartiallyInArrears"
      ? balanceDetails?.accountBalNotSchedule -
        balanceDetails?.accountOverdueNotSchedule
      : balanceDetails?.accountBalNotSchedule;

  useEffect(() => {
    if (!showBanner) {
      const isHomeBannerDismissed = getItem("isHomeBannerDismissed");
      if (!isHomeBannerDismissed) {
        setShowBanner(true);
      }
    }
  }, [showBanner]);

  const onCloseBannerClicked = () => {
    setShowBanner(false);
    setItem("isHomeBannerDismissed", true);
  };

  return (
    <>
      {showBanner && (
        <NotificationBanner
          variant={`${
            balanceDetails?.balanceStatus === "InArrears" ||
            balanceDetails?.balanceStatus === "PartiallyInArrears"
              ? "warning"
              : "info"
          }`}
        >
          <div>
            <div className="p-3">
              <Row>
                <Col xs={2} sm={1}>
                  {(balanceDetails?.balanceStatus === "InArrears" ||
                    balanceDetails?.balanceStatus === "PartiallyInArrears") && (
                    <>
                      <div className="text-warning">
                        <WarningOutlineIcon size="lg" />
                      </div>
                    </>
                  )}
                  {balanceDetails?.balanceStatus === "Due" && (
                    <div>
                      <InfoIcon
                        title="InfoIcon"
                        className="text-info"
                        size="md"
                      />
                    </div>
                  )}
                </Col>
                <Col xs={8} sm={10}>
                  <Row>
                    <Col lg={7} xs={12}>
                      <div
                        className={`text-center text-lg-start ${magicStrings.maskInformation}`}
                      >
                        {balanceDetails?.paymentMethod === "DirectDebit" && (
                          <>
                            {balanceDetails?.balanceStatus === "Due" && (
                              <>
                                {balanceDetails?.accountBalNotSchedule ===
                                  balanceDetails.amountDue && (
                                  <>
                                    Your payment of{" "}
                                    {formatCurrency(
                                      balanceDetails.accountBalNotSchedule
                                    )}{" "}
                                    is due now.
                                  </>
                                )}
                                {balanceDetails?.accountBalNotSchedule !==
                                  balanceDetails?.amountDue &&
                                  balanceDetails.accountBalNotSchedule !==
                                    0.0 && (
                                    <>
                                      Your payment of{" "}
                                      {formatCurrency(
                                        balanceDetails?.accountBalNotSchedule
                                      )}{" "}
                                      is due now.
                                      <br />
                                      We'll collect your next payment of{" "}
                                      {formatCurrency(
                                        balanceDetails?.amountDueDd
                                      )}{" "}
                                      by Direct Debit on{" "}
                                      {formatDate(balanceDetails?.nextDdDate)}.
                                    </>
                                  )}
                              </>
                            )}
                            {balanceDetails?.balanceStatus === "InArrears" && (
                              <>
                                {balanceDetails?.accountOverdueNotSchedule ===
                                  balanceDetails?.amountOverdue && (
                                  <>
                                    You are behind with your payments. Please
                                    pay{" "}
                                    {formatCurrency(
                                      balanceDetails?.accountOverdueNotSchedule
                                    )}{" "}
                                    to bring your account up to date.
                                  </>
                                )}
                                {balanceDetails?.accountOverdueNotSchedule !==
                                  balanceDetails?.amountOverdue &&
                                  balanceDetails?.accountOverdueNotSchedule !==
                                    0.0 && (
                                    <>
                                      You are behind with your payments. Please
                                      pay{" "}
                                      {formatCurrency(
                                        balanceDetails?.accountOverdueNotSchedule
                                      )}{" "}
                                      to bring your account up to date. <br />
                                      We'll collect your next payment of{" "}
                                      {formatCurrency(
                                        balanceDetails?.amountDueDd
                                      )}{" "}
                                      by Direct Debit on{" "}
                                      {formatDate(balanceDetails?.nextDdDate)}.
                                    </>
                                  )}
                              </>
                            )}
                            {balanceDetails.balanceStatus ===
                              "PartiallyInArrears" && (
                              <>
                                {accountDueNotSchedule === dueAmount &&
                                  balanceDetails?.accountOverdueNotSchedule ===
                                    balanceDetails?.amountOverdue && (
                                    <>
                                      You are behind with your payments. Please
                                      pay{" "}
                                      {formatCurrency(
                                        balanceDetails?.accountOverdueNotSchedule
                                      )}{" "}
                                      to bring your account up to date. <br />
                                      You also have a payment of{" "}
                                      {formatCurrency(
                                        accountDueNotSchedule
                                      )}{" "}
                                      that is due now.
                                    </>
                                  )}

                                {accountDueNotSchedule === 0.0 &&
                                  (balanceDetails?.accountOverdueNotSchedule ===
                                    balanceDetails?.amountOverdue ||
                                    (balanceDetails?.accountOverdueNotSchedule !==
                                      balanceDetails?.amountOverdue &&
                                      balanceDetails.accountOverdueNotSchedule !==
                                        0.0)) && (
                                    <>
                                      You are behind with your payments. Please
                                      pay{" "}
                                      {formatCurrency(
                                        balanceDetails?.accountOverdueNotSchedule
                                      )}{" "}
                                      to bring your account up to date.
                                      <br />
                                      We'll collect your next payment of{" "}
                                      {formatCurrency(
                                        balanceDetails?.amountDueDd
                                      )}{" "}
                                      by Direct Debit on{" "}
                                      {formatDate(balanceDetails?.nextDdDate)}.
                                    </>
                                  )}

                                {balanceDetails?.accountOverdueNotSchedule ===
                                  0.0 &&
                                  (accountDueNotSchedule === dueAmount ||
                                    (accountDueNotSchedule !== dueAmount &&
                                      accountDueNotSchedule !== 0.0)) && (
                                    <>
                                      Your payment of{" "}
                                      {formatCurrency(accountDueNotSchedule)} is
                                      due now.
                                      <br />
                                      We'll collect your next payment of{" "}
                                      {formatCurrency(
                                        balanceDetails?.amountDueDd
                                      )}{" "}
                                      by Direct Debit on{" "}
                                      {formatDate(balanceDetails?.nextDdDate)}.
                                    </>
                                  )}

                                {((balanceDetails?.accountOverdueNotSchedule !==
                                  balanceDetails?.amountOverdue &&
                                  balanceDetails?.accountOverdueNotSchedule !==
                                    0.0 &&
                                  accountDueNotSchedule !== dueAmount &&
                                  accountDueNotSchedule !== 0.0) ||
                                  (balanceDetails?.accountOverdueNotSchedule ===
                                    balanceDetails?.amountOverdue &&
                                    accountDueNotSchedule !== dueAmount &&
                                    accountDueNotSchedule !== 0.0) ||
                                  (balanceDetails?.accountOverdueNotSchedule !==
                                    balanceDetails?.amountOverdue &&
                                    balanceDetails?.accountOverdueNotSchedule !==
                                      0.0 &&
                                    accountDueNotSchedule === dueAmount)) && (
                                  <>
                                    You are behind with your payments. Please
                                    pay{" "}
                                    {formatCurrency(
                                      balanceDetails?.accountOverdueNotSchedule
                                    )}{" "}
                                    to bring your account up to date.
                                    <br />
                                    You also have a payment of{" "}
                                    {formatCurrency(accountDueNotSchedule)} that
                                    is due now.
                                    <br />
                                    We'll collect your next payment of{" "}
                                    {formatCurrency(
                                      balanceDetails?.amountDueDd
                                    )}{" "}
                                    by Direct Debit on{" "}
                                    {formatDate(balanceDetails?.nextDdDate)}.
                                  </>
                                )}
                              </>
                            )}

                            {((balanceDetails?.balanceStatus === "Due" &&
                              balanceDetails?.accountBalNotSchedule === 0.0) ||
                              (balanceDetails?.balanceStatus === "InArrears" &&
                                balanceDetails?.accountOverdueNotSchedule ===
                                  0.0) ||
                              (balanceDetails?.balanceStatus ===
                                "PartiallyInArrears" &&
                                accountDueNotSchedule === 0.0 &&
                                balanceDetails?.accountOverdueNotSchedule ===
                                  0.0)) && (
                              <>
                                We'll collect your next payment of{" "}
                                {formatCurrency(balanceDetails.amountDueDd)} by
                                Direct Debit on{" "}
                                {formatDate(balanceDetails?.nextDdDate)}.
                              </>
                            )}
                          </>
                        )}

                        {balanceDetails?.paymentMethod === "Other" &&
                          balanceDetails.balanceStatus === "Due" && (
                            <>
                              Your next payment of{" "}
                              {formatCurrency(balanceDetails?.amountDue)} is due
                              immediately.
                            </>
                          )}
                        {balanceDetails?.paymentMethod === "Other" &&
                          balanceDetails?.balanceStatus ===
                            "PartiallyInArrears" && (
                            <>
                              You need to pay{" "}
                              {formatCurrency(balanceDetails?.amountOverdue)} to
                              catch up with your payments your account is
                              overdue.
                              <br />
                              You also need to pay {formatCurrency(
                                dueAmount
                              )}{" "}
                              which is due immediately.
                            </>
                          )}
                        {balanceDetails?.paymentMethod === "Other" &&
                          balanceDetails?.balanceStatus === "InArrears" && (
                            <>
                              You need to pay{" "}
                              {formatCurrency(balanceDetails?.amountDue)} to
                              catch up with your payments your account is
                              overdue.
                            </>
                          )}
                      </div>
                      {!(
                        balanceDetails?.paymentMethod === "DirectDebit" &&
                        ((balanceDetails?.balanceStatus === "Due" &&
                          balanceDetails?.accountBalNotSchedule === 0.0) ||
                          (balanceDetails?.balanceStatus ===
                            "PartiallyInArrears" &&
                            accountDueNotSchedule === 0.0 &&
                            balanceDetails?.accountOverdueNotSchedule ===
                              0.0) ||
                          (balanceDetails?.balanceStatus === "InArrears" &&
                            balanceDetails?.accountOverdueNotSchedule === 0.0))
                      ) && (
                        <>
                          <br />
                          <div className="d-flex flex-column gap-3 col-12 col-sm-8  my-3 mx-auto">
                            <Button
                              id="btnPaynow"
                              variant="outline-primary"
                              onClick={() => onPayClicked && onPayClicked()}
                            >
                              Pay now
                            </Button>
                          </div>
                        </>
                      )}
                    </Col>
                    <Col lg={5} xs={12}>
                      <p className="text-center text-lg-start my-sm-3 my-lg-0 mx-md-3">
                        {isHomePage ? (
                          <>
                            Visit My Transactions for more details, or{" "}
                            <b>
                              <Link to="/payments/make-a-payment">
                                Money worries
                              </Link>
                            </b>{" "}
                            for any guidance.
                          </>
                        ) : (
                          <>
                            If you're finding it hard to keep up with your
                            payments, Visit our{" "}
                            <b>
                              <Link
                                to={
                                  userRegulated === true
                                    ? "/help/money-worries"
                                    : "/help/money-worries-non-regulated"
                                }
                              >
                                Money worries
                              </Link>
                            </b>{" "}
                            page.
                          </>
                        )}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={2} sm={1}>
                  <div className="d-flex flex-row-reverse">
                    <CloseButton onClick={onCloseBannerClicked} />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </NotificationBanner>
      )}
    </>
  );
};

export default HomeBanner;
