import * as yup from "yup";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import steps from "../complaint-steps";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import * as fieldSchemas from "../../../component/forms/schemas/fields";
import { GenericTextArea } from "../../../component/forms/fields/field-utils/field-utils";
import ComplaintNavigation from "../../../component/complaint-navigation/ComplaintNavigation";
import {
  step,
  complaintDetails,
} from "../../../redux/features/complaints/complaintSlice";

const ComplaintDetailsPage = () => {
  let complaintTitle, previousUrl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOptions = useSelector((state) => state.complaint);
  const selectedCategory =
    selectedOptions?.complaintCategory?.categoryDescription;
  const selectedReason = selectedOptions?.complaintReason?.reasonDescription;
  const defaultValues = selectedOptions?.complaintDetails;

  useComplaintStepCheck(steps.complaintDetails, "/complaint/reasons");

  if (selectedCategory?.toUpperCase() !== "SOMETHING ELSE") {
    complaintTitle = `${selectedCategory}: ${selectedReason}`;
    previousUrl = "/complaint/reasons";
  } else {
    complaintTitle = "Your complaint details";
    previousUrl = "/complaint/categories";
  }

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.complaintDescriptionSchema,
        ...fieldSchemas.impactDescriptionSchema,
        ...fieldSchemas.customerExpectationSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    dispatch(
      complaintDetails({
        complaintDescription: data?.complaintDescription,
        impactDescription: data?.impactDescription,
        customerExpectation: data?.customerExpectation,
      })
    );
    dispatch(step(steps.contactDetails));
    navigate("/complaint/contact-details", { replace: true });
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column gap-3 col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 fw-bold">{complaintTitle}</h2>
        <div>
          Answer all questions before you continue. Please don't include
          confidential information such as health issues or bank details.
        </div>
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            name="QuestionnaireForm"
          >
            <GenericTextArea
              maxLength="1500"
              isCountRequired={true}
              currentlength={
                defaultValues.complaintDescription.trimStart().length
              }
              className="bg-transparent"
              controlId="complaintDescription"
              labelText="Please tell us what's gone wrong, when the problem first occurred and what's happened since"
            />
            <GenericTextArea
              maxLength="1500"
              isCountRequired={true}
              currentlength={defaultValues.impactDescription.trimStart().length}
              className="bg-transparent"
              controlId="impactDescription"
              labelText="What impact has the problem had on you?"
            />
            <GenericTextArea
              maxLength="500"
              isCountRequired={true}
              currentlength={
                defaultValues.customerExpectation.trimStart().length
              }
              className="bg-transparent"
              controlId="customerExpectation"
              labelText="What would you like us to do to resolve your complaint?"
            />
            <div className="mt-4">
              <ComplaintNavigation
                previousName="Back"
                previousUrl={previousUrl}
              />
            </div>
          </Form>
        </FormProvider>
      </div>
    </BasePage>
  );
};

export default ComplaintDetailsPage;
