import * as yup from "yup";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import steps from "../complaint-steps";
import magicStrings from "../../../utils/magic-string";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import { useComplaintCategories } from "../../../api/hooks/complaints";
import * as fieldSchemas from "../../../component/forms/schemas/fields";
import { GenericRadioSelectField } from "../../../component/forms/fields/field-utils/field-utils";
import ComplaintNavigation from "../../../component/complaint-navigation/ComplaintNavigation";
import {
  step,
  complaintCategory,
} from "../../../redux/features/complaints/complaintSlice";

const ComplaintCategoriesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOptions = useSelector((state) => state.complaint);
  const selectedCategory = selectedOptions?.complaintCategory;
  const defaultValues = {
    complaintRadio: selectedCategory?.categoryId?.toString(),
  };

  useComplaintStepCheck(
    steps.complaintCategories,
    "/complaint/vehicle-selection"
  );

  const { data: complaintCategories, isError } = useComplaintCategories();

  useEffect(() => {
    if (isError) {
      dispatch(step(steps.complaintFailure));
      navigate("/complaint/failure", { replace: true });
    }
  }, [isError, navigate, dispatch]);

  const remapCategoriesToRadio = (categories) => {
    if (!categories) return;
    let updatedComplaintCategories = [];
    categories?.forEach((category) => {
      let option = {
        id: category?.categoryId.toString(),
        text: category?.categoryDescription,
      };
      updatedComplaintCategories.push(option);
    });

    return updatedComplaintCategories;
  };

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.complaintRadioSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    const selectedOption = complaintCategories.find(
      (category) => category.categoryId.toString() === data.complaintRadio
    );
    const selectedCategory = selectedOption?.categoryDescription;
    dispatch(complaintCategory(selectedOption));
    if (selectedCategory?.toUpperCase() === "SOMETHING ELSE") {
      dispatch(step(steps.complaintDetails));
      navigate("/complaint/details", { replace: true });
    } else {
      dispatch(step(steps.complaintReasons));
      navigate("/complaint/reasons", { replace: true });
    }
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column gap-3 col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 fw-bold">Tell us about your complaint</h2>
        <div>
          Finding out why you're unhappy helps us to send your complaint to the
          right team.
        </div>

        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            name="QuestionnaireForm"
          >
            {complaintCategories && complaintCategories.length > 0 && (
              <div className={magicStrings.maskInformation}>
                <GenericRadioSelectField
                  controlId="complaintRadio"
                  radioText="What's your complaint about?"
                  options={remapCategoriesToRadio(complaintCategories)}
                />
              </div>
            )}
            <ComplaintNavigation
              previousName="Back"
              previousUrl="/complaint/vehicle-selection"
            />
          </Form>
        </FormProvider>
      </div>
    </BasePage>
  );
};

export default ComplaintCategoriesPage;
