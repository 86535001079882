import { useQuery } from "@tanstack/react-query";
import apiKeys from "../error-handling/api-keys";
import queryKeys from "../react-query/queryKeys";
import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const getComplaintCategories = async (axios) => {
  return axios
    .get(`/complaints/categories`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.complaints),
    })
    .then((res) => {
      return res.data;
    });
};

const getComplaintReasons = async (axios, categoryId) => {
  return axios
    .get(`/complaints/${categoryId}/reasons`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.complaints),
    })
    .then((res) => {
      return res.data;
    });
};

const complaintSubmit = async (axios, payload) => {
  return axios
    .post("/complaints/submit", payload, {
      headers: attachApiKeyHeaderToRequest(apiKeys.complaints),
    })
    .then((res) => {
      return res.data;
    });
};

const useComplaintCategories = () => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(
    queryKeys.complaints.category,
    () => getComplaintCategories(axios),
    { cacheTime: 10 * 60 * 1000, staleTime: 10 * 60 * 1000 }
  );
  return {
    data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useComplaintReasons = (categoryId) => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(
    queryKeys.complaints.reason(categoryId),
    () => getComplaintReasons(axios, categoryId),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
  return {
    data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useComplaintSubmit = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((payload) => complaintSubmit(axios, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

export { useComplaintCategories, useComplaintReasons, useComplaintSubmit };
