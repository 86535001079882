import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import steps from "../complaint-steps";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import { reset } from "../../../redux/features/complaints/complaintSlice";

const ExistingComplaintPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useComplaintStepCheck(steps.existingComplaint, "/complaint/make-complaint");

  const onNavigate = () => {
    dispatch(reset());
    navigate("/home");
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 mb-3 fw-bold">Your existing complaint</h2>
        <div className="mb-3">
          When you tell us something's wrong, we try to put things right within
          five working days.
        </div>
        <div className="mb-3">
          It can sometimes take longer, depending on the problem.
        </div>
        <h2 className="h4 fw-bold">Our complaint response times</h2>
        <div className="border border-primary py-3 mb-3 border-radius d-flex justify-content-center align-items-center">
          <div className="w-25 px-2">
            <span className="text-primary h4 fw-bold">1-5 days</span>
          </div>
          <div className="w-75 ps-3 pe-1 border-start border-primary">
            We'll reply within five working days with your reference number,
            while we look into your complaint.
          </div>
        </div>
        <div className="border border-primary py-3 mb-3 border-radius d-flex justify-content-center align-items-center">
          <div className="w-25 px-2">
            <span className="text-primary h4 fw-bold">4 weeks</span>
          </div>
          <div className="w-75 ps-3 pe-1 border-start border-primary">
            If we can't fix things within four weeks, we'll send a text message
            to let you know our next steps
          </div>
        </div>
        <div className="border border-primary py-3 mb-3 border-radius d-flex justify-content-center align-items-center">
          <div className="w-25 px-2">
            <span className="text-primary h4 fw-bold">8 weeks</span>
          </div>
          <div className="w-75 ps-3 pe-1 border-start border-primary">
            If we haven't solved the problem after eight weeks, we'll send
            another text message to update you while we continue our
            investigation.
          </div>
        </div>
        <div className="mt-2">
          If you have new information that you think might help, please call us
          on <Link to="tel:0800 068 4025">0800 068 4025</Link>. Lines are open
          Monday to Friday 8.30am - 5.30pm. We close at weekends and on bank
          holidays.
        </div>
        <div className="my-3">
          We'll ask for your complaint reference number, so it helps if you have
          it ready.
        </div>
        <div className="w-50 mx-auto">
          <Button
            id="btnHome"
            className="text-decoration-none w-100"
            onClick={onNavigate}
          >
            Home
          </Button>
        </div>
      </div>
    </BasePage>
  );
};

export default ExistingComplaintPage;
