import * as yup from "yup";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import steps from "../complaint-steps";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import * as fieldSchemas from "../../../component/forms/schemas/fields";
import { GenericRadioSelectField } from "../../../component/forms/fields/field-utils/field-utils";
import ComplaintNavigation from "../../../component/complaint-navigation/ComplaintNavigation";
import {
  step,
  reset,
  complaintType,
} from "../../../redux/features/complaints/complaintSlice";

const MakeComplaintPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOptions = useSelector((state) => state.complaint);
  const selectedComplaintType = selectedOptions?.complaintType;
  const defaultValues = {
    complaintRadio: selectedComplaintType?.id,
  };
  const complainTypes = [
    { id: "1", text: "Yes" },
    { id: "2", text: "No" },
  ];

  useComplaintStepCheck(steps.makeComplaint, "/complaint");

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.complaintRadioSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onCancel = () => {
    dispatch(reset());
    navigate("/home");
  };

  const onSubmit = (data) => {
    const selectedOption = complainTypes.find(
      (x) => x.id === data.complaintRadio
    );
    dispatch(complaintType(selectedOption));
    if (selectedOption?.text === "No") {
      dispatch(step(steps.complaintCategories));
      navigate("/complaint/vehicle-selection");
    } else {
      dispatch(step(steps.existingComplaint));
      navigate("/complaint/existing-complaint");
    }
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column gap-3 col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 fw-bold">Make a complaint</h2>
        <div>We're sorry you're unhappy.</div>
        <div>
          Let's find out what's gone wrong, so we can try to put things right as
          quickly as possible.
        </div>

        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            name="QuestionnaireForm"
          >
            <GenericRadioSelectField
              controlId="complaintRadio"
              radioText="Are you contacting us about an existing complaint?"
              options={complainTypes}
            />
            <ComplaintNavigation isLink={false} callBack={onCancel} />
          </Form>
        </FormProvider>
      </div>
    </BasePage>
  );
};

export default MakeComplaintPage;
