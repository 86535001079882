import { createSlice } from "@reduxjs/toolkit";

import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  step: 0,
  complaintType: "",
  contractDetails: "",
  complaintCategory: "",
  complaintReason: "",
  complaintDetails: {
    complaintDescription: "",
    impactDescription: "",
    customerExpectation: "",
  },
  contactDetails: {
    fullName: "",
    mobileNumber: "",
    workNumber: "",
    homeNumber: "",
    emailAddress: "",
    userAddress: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      county: "",
      postCode: "",
    },
  },
};

export const complaintSlice = createSlice({
  name: "complaint",
  initialState,
  reducers: {
    step: (state, action) => {
      state.step = action.payload;
    },
    complaintType: (state, action) => {
      state.complaintType = action.payload;
    },
    contractDetails: (state, action) => {
      state.contractDetails = action.payload;
    },
    complaintCategory: (state, action) => {
      state.complaintCategory = action.payload;
    },
    complaintReason: (state, action) => {
      state.complaintReason = action.payload;
    },
    complaintDetails: (state, action) => {
      state.complaintDetails = action.payload;
    },
    contactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  step,
  reset,
  complaintType,
  contractDetails,
  complaintCategory,
  complaintReason,
  complaintDetails,
  contactDetails,
} = complaintSlice.actions;

export default complaintSlice.reducer;
