import * as yup from "yup";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import steps from "../complaint-steps";
import magicStrings from "../../../utils/magic-string";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import { useComplaintReasons } from "../../../api/hooks/complaints";
import * as fieldSchemas from "../../../component/forms/schemas/fields";
import { GenericRadioSelectField } from "../../../component/forms/fields/field-utils/field-utils";
import ComplaintNavigation from "../../../component/complaint-navigation/ComplaintNavigation";
import {
  step,
  complaintReason,
} from "../../../redux/features/complaints/complaintSlice";

const ComplaintReasonsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOptions = useSelector((state) => state.complaint);
  const selectedCategory = selectedOptions?.complaintCategory;
  const selectedReason = selectedOptions?.complaintReason;
  const defaultValues = {
    complaintRadio: selectedReason?.reasonId?.toString(),
  };

  useComplaintStepCheck(steps.complaintReasons, "/complaint/categories");

  const { data: complaintReasons, isError } = useComplaintReasons(
    selectedCategory?.categoryId
  );

  useEffect(() => {
    if (isError) {
      dispatch(step(steps.complaintFailure));
      navigate("/complaint/failure", { replace: true });
    }
  }, [isError, navigate, dispatch]);

  const remapCategoriesToRadio = (reasons) => {
    if (!reasons) return;
    let updatedComplaintReasons = [];
    reasons?.forEach((reason) => {
      let option = {
        id: reason?.reasonId.toString(),
        text: reason?.reasonDescription,
      };
      updatedComplaintReasons.push(option);
    });

    return updatedComplaintReasons;
  };

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.complaintRadioSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    const selectedOption = complaintReasons.find(
      (category) => category.reasonId.toString() === data.complaintRadio
    );
    dispatch(complaintReason(selectedOption));
    dispatch(step(steps.complaintDetails));
    navigate("/complaint/details", { replace: true });
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column gap-3 col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 fw-bold">{selectedCategory?.categoryDescription}</h2>

        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            name="QuestionnaireForm"
          >
            {complaintReasons && complaintReasons.length > 0 && (
              <div className={magicStrings.maskInformation}>
                <GenericRadioSelectField
                  controlId="complaintRadio"
                  radioText="What does your complaint relate to?"
                  options={remapCategoriesToRadio(complaintReasons)}
                />
              </div>
            )}
            <ComplaintNavigation
              previousName="Back"
              previousUrl={"/complaint/categories"}
            />
          </Form>
        </FormProvider>
      </div>
    </BasePage>
  );
};

export default ComplaintReasonsPage;
