import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import steps from "../complaint-steps";
import { step } from "../../../redux/features/complaints/complaintSlice";

/**
 * Ensures the current step in complaint journey.
 * Redirects the user to
 * @param {number} stepToCheck - Step to check that page needs to be.
 * @param {string} redirectionPath - Path to redirect if the saved step is less than minStep.
 */
const useComplaintStepCheck = (stepToCheck, redirectionPath) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStep = useSelector((state) => state.complaint.step);

  useEffect(() => {
    if (!currentStep || currentStep < stepToCheck) {
      if (currentStep === 0) {
        navigate("/complaint", { replace: true });
      } else {
        navigate(redirectionPath, { replace: true });
      }
    } else if (
      currentStep !== stepToCheck &&
      currentStep !== steps.complaintFailure &&
      currentStep !== steps.complaintSuccess
    ) {
      dispatch(step(stepToCheck));
    }
  }, [navigate, currentStep, dispatch, stepToCheck, redirectionPath]);
};

export default useComplaintStepCheck;
