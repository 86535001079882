import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const MoneyWorriesIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M30 25.2858V7.74609H2.14282V25.2858"
      stroke="#4B4B4B"
      stroke-width="3"
    />
    <ellipse
      cx="16.0714"
      cy="6.71416"
      rx="13.9286"
      ry="5.15874"
      fill="white"
      stroke="#4B4B4B"
      stroke-width="3"
    />
    <path
      d="M30 12.9048C30 15.7539 23.764 18.0635 16.0714 18.0635C8.37886 18.0635 2.14282 15.7539 2.14282 12.9048"
      stroke="#4B4B4B"
      stroke-width="3"
    />
    <path
      d="M30 19.095C30 21.9441 23.764 24.2537 16.0714 24.2537C8.37886 24.2537 2.14282 21.9441 2.14282 19.095"
      stroke="#4B4B4B"
      stroke-width="3"
    />
    <path
      d="M30 25.2856C30 28.1347 23.764 30.4444 16.0714 30.4444C8.37886 30.4444 2.14282 28.1347 2.14282 25.2856"
      stroke="#4B4B4B"
      stroke-width="3"
    />
  </svg>
);

MoneyWorriesIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { MoneyWorriesIcon };
export default MoneyWorriesIcon;
