import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import steps from "../complaint-steps";
import BasePage from "../../basepages/basepage/BasePage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import { reset } from "../../../redux/features/complaints/complaintSlice";

const ComplaintFailurePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useComplaintStepCheck(steps.complaintFailure, "/home");

  const onNavigate = () => {
    dispatch(reset());
    navigate("/home");
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column col-12 col-lg-6 col-xl-5 mx-auto col-sm-8 ">
        <h2 className="h3 mb-3 fw-bold">
          Sorry, we're having some technical problems
        </h2>
        <div className="mb-3">
          We're having problems with our online complaint form. We're sorry for
          any inconvenience.
        </div>
        <div className="mb-3">
          We still want to hear about your complaint as soon as possible:
        </div>
        <div className="border border-primary mb-3 py-3 ps-3 pe-2 border-radius">
          <h2 className="mb-2 h5 fw-bold">Email us</h2>
          <div>
            You can email us at{" "}
            <Link
              className="text-break"
              to="mailto:LACustomerResolutionMailbox@lexautolease.co.uk"
            >
              LACustomerResolutionMailbox@lexautolease.co.uk
            </Link>
          </div>
          <div className="mt-3">
            Please include any evidence, such as pictures and your service
            history.
          </div>
        </div>
        <div className="border border-primary mb-3 py-3 ps-3 pe-2 border-radius">
          <h2 className="mb-2 h5 fw-bold">Call us</h2>
          <div>
            If you'd prefer to speak to us, call us on{" "}
            <Link to="tel:0800 068 4025">0800 068 4025</Link> and select option
            2.
          </div>
          <div>
            Lines are open Monday to Friday 8.30am - 5.30pm. We close at
            weekends and on bank holidays.
          </div>
        </div>
        <div className="border border-primary mb-3 py-3 ps-3 pe-2 border-radius">
          <h2 className="mb-2 h5 fw-bold">Write to us</h2>
          <div>
            If you'd rather send your complaint in the post, please include your
            customer account number and vehicle registration number.
          </div>
          <div className="mt-3">You can write to us at:</div>
          <div className="mt-3">
            <div>Lex Autolease Customer Resolution Department</div>
            <div>Heathside Park</div>
            <div>Heathside Park Road</div>
            <div>Stockport</div>
            <div>Cheshire</div>
            <div>SK3 0RB</div>
          </div>
        </div>
        <div className="w-50 mx-auto mt-3">
          <Button
            id="btnHome"
            className="text-decoration-none w-100"
            onClick={onNavigate}
          >
            Home
          </Button>
        </div>
      </div>
    </BasePage>
  );
};

export default ComplaintFailurePage;
