const complaintSteps = {
  howToComplain: 0,
  makeComplaint: 1,
  vehicleSelection: 2,
  complaintCategories: 3,
  complaintReasons: 4,
  complaintDetails: 5,
  contactDetails: 6,
  complaintSummary: 7,
  complaintSuccess: 8,
  complaintFailure: 9,
  existingComplaint: 10,
};

export default complaintSteps;
