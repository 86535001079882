import { Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import steps from "../complaint-steps";
import magicStrings from "../../../utils/magic-string";
import BasePage from "../../basepages/basepage/BasePage";
import { setItem } from "../../../storage/SessionStorage";
import useComplaintStepCheck from "../hooks/useComplaintStepCheck";
import { useComplaintSubmit } from "../../../api/hooks/complaints";
import ComplaintNavigation from "../../../component/complaint-navigation/ComplaintNavigation";
import { step, reset } from "../../../redux/features/complaints/complaintSlice";

const ComplaintSummaryPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filledDetails = useSelector((state) => state.complaint);
  useComplaintStepCheck(steps.complaintSummary, "/complaint/contact-details");

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmitSuccessful = (data) => {
    dispatch(reset());
    dispatch(step(steps.complaintSuccess));
    setItem("complaintNo", data.complaintNo);
    navigate("/complaint/success", { replace: true });
  };

  const onSubmitFailure = () => {
    dispatch(step(steps.complaintFailure));
    navigate("/complaint/failure", { replace: true });
  };

  const { mutate } = useComplaintSubmit(onSubmitSuccessful, onSubmitFailure);

  const onSubmit = () => {
    const payload = {
      vehicleReg:
        filledDetails?.contractDetails?.vehicle?.registrationNumber ?? "",
      categoryId: filledDetails?.complaintCategory?.categoryId,
      episodeId: filledDetails?.complaintReason?.episodeId,
      reasonId: filledDetails?.complaintReason?.reasonId,
      complaintDescription:
        filledDetails?.complaintDetails?.complaintDescription,
      impactDescription: filledDetails?.complaintDetails?.impactDescription,
      customerExpectation: filledDetails?.complaintDetails?.customerExpectation,
    };
    mutate(payload);
  };

  const onNavigate = (path) => {
    return (
      <span
        onClick={() => navigate(path)}
        className="text-decoration-underline fw-bold text-primary cursor-pointer"
      >
        Change
      </span>
    );
  };

  const personalDetails = (contactDetails) => {
    return (
      <>
        {contactDetails?.fullName && <div>{contactDetails?.fullName} </div>}
        {contactDetails?.userAddress?.addressLine1 && (
          <div>{contactDetails?.userAddress?.addressLine1}</div>
        )}
        {contactDetails?.userAddress?.addressLine2 && (
          <div>{contactDetails?.userAddress?.addressLine2}</div>
        )}
        {contactDetails?.userAddress?.addressLine3 && (
          <div>{contactDetails?.userAddress?.addressLine3}</div>
        )}
        {contactDetails?.userAddress?.city && (
          <div>{contactDetails?.userAddress?.city}</div>
        )}
        {contactDetails?.userAddress?.county && (
          <div>{contactDetails?.userAddress?.county}</div>
        )}
        {contactDetails?.userAddress?.postCode && (
          <div>{contactDetails?.userAddress?.postCode}</div>
        )}
        {contactDetails?.mobileNumber && (
          <div>{contactDetails?.mobileNumber} </div>
        )}
        {contactDetails?.emailAddress && (
          <div>{contactDetails?.emailAddress}</div>
        )}
      </>
    );
  };

  const reviewDetails = (question, answer, changeUrl, isAddress = false) => {
    return (
      <Row className="border-top m-0 mt-1 py-4">
        <div className="col-md-4 fw-bold ps-0">{question}</div>
        <div
          className={`col-md-6 ps-0 text-break ${magicStrings.maskInformation}`}
        >
          {isAddress === true ? personalDetails(answer) : answer}
        </div>
        <div className="col-md-2 px-0 text-sm-start text-md-end">
          {onNavigate(changeUrl)}
        </div>
      </Row>
    );
  };

  return (
    <BasePage pageTitle={""}>
      <div className="d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 mx-auto col-sm-8 ">
        <h2 className="h3 fw-bold">Review your complaint</h2>
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            name="reviewComplaintForm"
          >
            <div className="mt-3 container ps-0 pe-0">
              {reviewDetails(
                "Which vehicle does your complaint relate to?",
                filledDetails?.contractDetails?.vehicle?.registrationNumber ??
                  filledDetails?.contractDetails,
                "/complaint/vehicle-selection"
              )}
              {reviewDetails(
                "What's your complaint about?",
                filledDetails?.complaintCategory?.categoryDescription,
                "/complaint/categories"
              )}
              {filledDetails?.complaintCategory?.categoryDescription?.toUpperCase() !==
              "SOMETHING ELSE"
                ? reviewDetails(
                    "What does your complaint relate to?",
                    filledDetails?.complaintReason?.reasonDescription,
                    "/complaint/reasons"
                  )
                : ""}
              {reviewDetails(
                "Please tell us what's gone wrong, when the problem first occurred and what's happened since.",
                filledDetails?.complaintDetails?.complaintDescription,
                "/complaint/details"
              )}
              {reviewDetails(
                "What impact has it had on you?",
                filledDetails?.complaintDetails?.impactDescription,
                "/complaint/details"
              )}
              {reviewDetails(
                "What would you like us to do to resolve your complaint?",
                filledDetails?.complaintDetails?.customerExpectation,
                "/complaint/details"
              )}
              {reviewDetails(
                "Please check everything's correct",
                filledDetails?.contactDetails,
                "/complaint/contact-details",
                true
              )}
            </div>
            <div className="mt-4">
              <ComplaintNavigation
                previousName="Back"
                nextName="Submit complaint"
                previousUrl="/complaint/contact-details"
              />
            </div>
          </Form>
        </FormProvider>
      </div>
    </BasePage>
  );
};

export default ComplaintSummaryPage;
