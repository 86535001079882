import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import steps from "../complaint-steps";
import useAuth from "../../../hooks/use-auth/useAuth";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import { step } from "../../../redux/features/complaints/complaintSlice";

const HowToComplainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const userRegulated = auth?.user?.regulated;
  const userRoles = auth?.user?.roles;
  const roleAssigned = userRoles?.includes("Complaint logging");

  const onStartComplaint = () => {
    dispatch(step(steps.makeComplaint));
    navigate("/complaint/make-complaint");
  };

  return (
    <BasePage pageTitle="How to complain">
      <p>
        If you're unhappy with our service, let us know straightaway and we'll
        do everything we can to put things right.
      </p>
      {!userRegulated && roleAssigned ? (
        <>
          <p>Our online form is the easiest way to get in touch:</p>
          <div className="d-flex flex-column col-12 col-lg-4 col-xl-3 col-sm-6 ">
            <Button id="btnStartComplaint" onClick={onStartComplaint}>
              Start your complaint
            </Button>
          </div>
          <h2 className="mt-5 h4 fw-bold">Other ways to get in touch:</h2>
        </>
      ) : (
        <h2 className="mt-4 h4 fw-bold">Ways to get in touch:</h2>
      )}

      <h2 className="mt-4 mb-2 h5 fw-bold">Call us</h2>
      <div>
        If you'd like to speak to us, call us on{" "}
        <Link to="tel:0800 068 4025">0800 068 4025</Link>.
      </div>
      <div className="mb-3">
        Lines are open Monday to Friday 8.30am - 5.30pm. We close at weekends
        and on bank holidays.
      </div>
      <h2 className="mt-4 mb-2 h5 fw-bold">Email us</h2>
      <div>
        You can email us at{" "}
        <Link
          className="text-break"
          to="mailto:LACustomerResolutionMailbox@lexautolease.co.uk"
        >
          LACustomerResolutionMailbox@lexautolease.co.uk
        </Link>
      </div>
      <div>
        Please include any evidence, such as pictures and your service history.
      </div>
      <h2 className="mt-4 mb-2 h5 fw-bold">Write to us</h2>
      <div className="mb-3">
        If you'd rather write to us, please include your customer account number
        and vehicle registration number:
      </div>
      <div>Lex Autolease Customer Resolution Department</div>
      <div>Heathside Park</div>
      <div>Heathside Park Road</div>
      <div>Stockport</div>
      <div>Cheshire</div>
      <div>SK3 0RB</div>
      <h2 className="mt-5 mb-4 h4 fw-bold">Independent review</h2>
      <p>
        If you're unhappy with how we deal with your complaint, you may be able
        to request free, independent advice from the following institutions:
      </p>
      <h2 className="mt-4 mb-3 h5 fw-bold">
        The British Vehicle Rental and Leasing Association (BVRLA)
      </h2>
      <div>
        The BVRLA offers free advice to customers who can't resolve a dispute
        directly.
      </div>
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.bvrla.co.uk/consumer-advice/making-a-complaint-adr/complaint-eligibility.html"
        >
          Find out more about the BVRLA,
        </a>{" "}
        or email them at{" "}
        <Link to="mailto:complaint@bvrla.co.uk">complaint@bvrla.co.uk</Link>
      </div>
      <div className="mt-4">
        You can also call them on{" "}
        <Link to="tel:01494 434747">01494 434747</Link>.
      </div>
      <h2 className="mt-4 mb-3 h5 fw-bold">
        The Financial Ombudsman Service (FOS)
      </h2>
      <div>
        The FOS also offers an impartial dispute resolution service, free of
        charge.
      </div>
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.financial-ombudsman.org.uk/"
        >
          Learn more about the FOS
        </a>{" "}
        and the services they offer.
      </div>
    </BasePage>
  );
};

export default HowToComplainPage;
