import { configureStore } from "@reduxjs/toolkit";
import vehicleCollectionReducer from "./features/vehicle-collection/vehicleCollectionSlice";
import earlyTerminationReducer from "./features/early-termination/earlyTerminationSlice";
import directDebitReducer from "./features/payments/directDebitSlice";
import contractAmendsReducer from "./features/contract-amends/contractAmendsSlice";
import supportNeedsReducer from "./features/support-needs/supportNeedsSlice";
import orderReviewReducer from "./features/order-review/orderReviewSlice";
import cardPaymentReducer from "./features/payments/cardPaymentSlice";
import complaintReducer from "./features/complaints/complaintSlice";

export const store = configureStore({
  reducer: {
    vehicleCollection: vehicleCollectionReducer,
    earlyTermination: earlyTerminationReducer,
    directDebit: directDebitReducer,
    contractAmends: contractAmendsReducer,
    supportNeeds: supportNeedsReducer,
    orderReview: orderReviewReducer,
    cardPayment: cardPaymentReducer,
    complaint: complaintReducer,
  },
});
