import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import Tile from "../../../../component/tile/Tile";
import TilesPlusMainContentBasePage from "../../../basepages/tiles-plus-main-content-basepage/TilesPlusMainContentBasePage";
import DirectDebitIcon from "../../../../component/icons/DirectDebitIcon";
import CardIcon from "../../../../component/icons/CardIcon";
import MoneyWorriesIcon from "../../../../component/icons/MoneyWorriesIcon";
import useAuth from "../../../../hooks/use-auth/useAuth";
import { useGetAccountBalance } from "../../../../api/hooks/account";
import {
  reset,
  step,
} from "../../../../redux/features/payments/cardPaymentSlice";
import cardPaymentSteps from "../card-payment-steps";
import magicStrings from "../../../../utils/magic-string";

const PaymentTiles = ({ directDebitUrl, userRegulated }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const onCardPayment = () => {
    dispatch(step(cardPaymentSteps.invoiceSelection));
    navigate("../card-payment/invoice-selection");
  };

  const onDirectDebit = () => {
    navigate(directDebitUrl);
  };

  return (
    <Row>
      <Tile
        cardId="direct-debit"
        cardLinkContent="Manage Direct Debit"
        isLink={false}
        callBack={onDirectDebit}
      >
        <>
          <DirectDebitIcon title="Direct debit" size="md" />
          <h2 className="my-3">Manage a Direct Debit</h2>

          <p className="mb-0">
            Direct Debit is the easiest way to stay on top of your payments.
          </p>
        </>
      </Tile>
      <Tile
        cardId="card-payment"
        cardLinkContent="Make an online payment"
        isLink={false}
        callBack={onCardPayment}
      >
        <>
          <CardIcon title="Card payment" size="md" />
          <h2 className="py-3">Pay by debit card</h2>
          <p className="mb-0">
            Online payments can be made with debit cards only. We don't accept
            credit cards.
          </p>
        </>
      </Tile>
      <Tile
        cardId="moneyWorries"
        cardLinkUrl={
          userRegulated === true
            ? "/help/money-worries"
            : "/help/money-worries-non-regulated"
        }
        cardLinkContent="View support"
        isLink={true}
      >
        <MoneyWorriesIcon title="Card payment" size="md" />
        <h2 className="py-3">Money worries</h2>
        <p className="mb-0">
          See how we can help you to control your finances.
        </p>
      </Tile>
    </Row>
  );
};

const MakeAPaymentPage = () => {
  const { auth } = useAuth();
  const userRegulated = auth?.user?.regulated;
  const { data } = useGetAccountBalance();
  const existingDDPaymentMethod = ["DirectDebit", "SpecialDirectDebit"];
  const paymentMethod = data?.paymentMethod;
  const directDebitUrl = existingDDPaymentMethod.includes(paymentMethod)
    ? "../existing-direct-debit"
    : "../new-direct-debit";

  return (
    <TilesPlusMainContentBasePage
      pageTitle="Payment support"
      tilesContent={
        <PaymentTiles
          directDebitUrl={directDebitUrl}
          userRegulated={userRegulated}
        />
      }
    >
      <h2>How to make payments</h2>
      <p>You can send us a payment using one of the methods below. </p>
      <br />
      <h3>Pay by debit card</h3>
      <p>
        To make a debit card payment over the phone, please call{" "}
        <Link to="tel:0800 302 9160">0800 302 9160</Link> (option 3) quoting
        fleet number{" "}
        <strong className={`${magicStrings.maskInformation}`}>
          {auth?.user?.fleet}
        </strong>
        .
      </p>
      <br />
      <h3>Pay by bank transfer</h3>
      <p>
        To make a payment by bank transfer or BACS, please use the details below
        quoting your fleet number{" "}
        <strong className={`${magicStrings.maskInformation}`}>
          {auth?.user?.fleet}
        </strong>{" "}
        as the payment reference.
      </p>
      Account name: <strong>Lex Autolease</strong>
      <br />
      Sort code: <strong>30-00-00</strong>
      <br />
      Account number: <strong>00241229</strong>
      <br />
      <br />
      <br />
      <h3>Pay by CHAPS</h3>
      <p>
        If you're thinking about paying by CHAPS, it's important to remember
        that your bank may charge you for each payment.
      </p>
      <p>
        To make a payment by CHAPS, please use the details below quoting your
        fleet number{" "}
        <strong className={`${magicStrings.maskInformation}`}>
          {auth?.user?.fleet}
        </strong>{" "}
        as the payment reference.
      </p>
      Account name: <strong>Lex Autolease</strong>
      <br />
      Sort code: <strong>30-15-99</strong>
      <br />
      Account number: <strong>00029210</strong>
      <br />
    </TilesPlusMainContentBasePage>
  );
};

export default MakeAPaymentPage;
